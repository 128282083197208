import { default as Contracttn1atQhxTYMeta } from "/home/runner/work/kisenya-public/kisenya-public/pages/Contract.vue?macro=true";
import { default as HomeK4Y4HbeRLmMeta } from "/home/runner/work/kisenya-public/kisenya-public/pages/Home.vue?macro=true";
import { default as ImprintdmVVD22tvTMeta } from "/home/runner/work/kisenya-public/kisenya-public/pages/Imprint.vue?macro=true";
import { default as PrivacyrMPvtwHsCdMeta } from "/home/runner/work/kisenya-public/kisenya-public/pages/Privacy.vue?macro=true";
export default [
  {
    name: Contracttn1atQhxTYMeta?.name ?? "Contract",
    path: Contracttn1atQhxTYMeta?.path ?? "/Contract",
    meta: Contracttn1atQhxTYMeta || {},
    alias: Contracttn1atQhxTYMeta?.alias || [],
    redirect: Contracttn1atQhxTYMeta?.redirect,
    component: () => import("/home/runner/work/kisenya-public/kisenya-public/pages/Contract.vue").then(m => m.default || m)
  },
  {
    name: HomeK4Y4HbeRLmMeta?.name ?? "Home",
    path: HomeK4Y4HbeRLmMeta?.path ?? "/Home",
    meta: HomeK4Y4HbeRLmMeta || {},
    alias: HomeK4Y4HbeRLmMeta?.alias || [],
    redirect: HomeK4Y4HbeRLmMeta?.redirect,
    component: () => import("/home/runner/work/kisenya-public/kisenya-public/pages/Home.vue").then(m => m.default || m)
  },
  {
    name: ImprintdmVVD22tvTMeta?.name ?? "Imprint",
    path: ImprintdmVVD22tvTMeta?.path ?? "/Imprint",
    meta: ImprintdmVVD22tvTMeta || {},
    alias: ImprintdmVVD22tvTMeta?.alias || [],
    redirect: ImprintdmVVD22tvTMeta?.redirect,
    component: () => import("/home/runner/work/kisenya-public/kisenya-public/pages/Imprint.vue").then(m => m.default || m)
  },
  {
    name: PrivacyrMPvtwHsCdMeta?.name ?? "Privacy",
    path: PrivacyrMPvtwHsCdMeta?.path ?? "/Privacy",
    meta: PrivacyrMPvtwHsCdMeta || {},
    alias: PrivacyrMPvtwHsCdMeta?.alias || [],
    redirect: PrivacyrMPvtwHsCdMeta?.redirect,
    component: () => import("/home/runner/work/kisenya-public/kisenya-public/pages/Privacy.vue").then(m => m.default || m)
  }
]