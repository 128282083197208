<template>
  <div>
    <div
      class="flex flex-row flex-nowrap justify-between items-center px-64 py-32"
    >
      <div
        class="rounded-8 flex-shrink"
        :class="{
          'cursor-pointer': $route.path !== '/' && $route.path !== '',
        }"
        @click="$router.push('/')"
      >
        <LogoSVG :fontControlled="false" class="w-144" />
      </div>
      <!-- <div class="flex flex-row flex-grow justify-center gap-24"> -->
      <!-- <div -->
      <!-- v-for="link in links" -->
      <!-- :key="link.slug" -->
      <!-- :class="{ -->
      <!-- 'cursor-pointer hover:bg-dark/10': $route.path !== link.slug, -->
      <!-- }" -->
      <!-- class="p-8 text-center rounded-8 relative" -->
      <!-- @click="$router.push(link.slug)" -->
      <!-- > -->
      <!-- {{ link.label }} -->
      <!-- <div -->
      <!-- v-if="$route.path === link.slug" -->
      <!-- class="bg-secondary-base h-2 -left-5% w-110% absolute" -->
      <!-- ></div> -->
      <!-- </div> -->
      <!-- </div> -->
      <div class="flex flexrow gap-8 justify-evenly flex-shrink">
        <KisenyaButton
          type="dark"
          label="Jetzt Vorschriften checken"
          @click="dialogs.showDialog('checks_duty')"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useDialogsStore } from "~/stores/dialogs";
import LogoSVG from "~/assets/logo_4.svg?component";

const dialogs = useDialogsStore();
const links = [
  {
    slug: "/about",
    label: "Über uns",
  },
  {
    slug: "/software",
    label: "Liftmanagement",
  },
  {
    slug: "/packages",
    label: "Preise",
  },
  {
    slug: "/contact",
    label: "Kontakt",
  },
];
</script>
