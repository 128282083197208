<template>
  <div class="relative overflow-hidden pt-24">
    <img
      src="~/assets/footer_1.png"
      class="absolute left-0 bottom-0 right-0 top-0 w-100% h-100% -z-20"
    />
    <img
      src="~/assets/footer_2.png"
      class="absolute left-0 bottom-0 right-0 top-0 w-100% h-100% -z-10"
    />
    <div
      class="absolute flex justify-between align-middle text-content-subtitle bottom-0 left-0 right-0 bg-[#CFDBF5] text-dark px-16 md:px-64 py-16"
    >
      <div class="flex flex-row gap-24">
        <div
          @click="$router.push('/imprint')"
          class="hover:underline underline-offset-4 cursor-pointer select-none"
        >
          Impressum
        </div>
        <div
          @click="$router.push('/privacy')"
          class="hover:underline underline-offset-4 cursor-pointer select-none"
        >
          Datenschutz
        </div>
        <div
          @click="$router.push('/contract')"
          class="hover:underline underline-offset-4 cursor-pointer select-none"
        >
          AGBs
        </div>
      </div>
      <div>© 2024 Kisenya GmbH</div>
    </div>
    <div class="py-128 px-16 md:px-64">
      <div class="flex justify-around h-100%">
        <div>
          <LogoWhiteIcon class="w-144" :fontControlled="false" />
        </div>
        <div class="text-white">
          <div class="text-contact-title mb-32">Kostenloser Check</div>
          <div class="flex justify-start">
            <KisenyaButton
              @click="dialogs.showDialog('checks_duty')"
              label="Jetzt Vorschriften checken"
              type="bright"
            />
          </div>
        </div>
        <div class="text-white">
          <div class="text-contact-title mb-32">Kontakt</div>
          <div
            class="flex flex-row gap-24 text-content-subtitle mb-12 hover:underline underline-offset-4 cursor-pointer"
          >
            <div>
              <MapIcon class="w-24" :fontControlled="false" />
            </div>
            <div>
              Ludwig-Pfau-Str.17<br />
              70176 Stuttgart
            </div>
          </div>
          <div
            @click="callPhone"
            class="flex flex-row gap-24 text-content-subtitle mb-12 hover:underline underline-offset-4 cursor-pointer"
          >
            <div>
              <PhoneIcon class="w-24" :fontControlled="false" />
            </div>
            <div>015792388694</div>
          </div>
          <div
            @click="sendMail"
            class="flex flex-row gap-24 text-content-subtitle mb-12 hover:underline underline-offset-4 cursor-pointer"
          >
            <div>
              <MailIcon class="w-24" :fontControlled="false" />
            </div>
            <div>service@kisenya.de</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import MapIcon from "~/assets/icons/placeholder-map.svg";
import PhoneIcon from "~/assets/icons/phone-ring.svg";
import MailIcon from "~/assets/icons/envelope-solid.svg";

import LogoWhiteIcon from "~/assets/logo_white.svg?component";

import { useDialogsStore } from "~/stores/dialogs";

const dialogs = useDialogsStore();

function sendMail() {
  window.open("mailto:services@kisenya.de");
}
function callPhone() {
  window.open("tel:015792388694");
}

function goToApplication() {
  window.open("https://app.kisenya.de/", "_blank");
}
</script>
